import $ from 'jquery';
import jQuery from 'jquery';

function customRange(dates)
	{ 
	if (this.id == 'pickup-date')
		{
		var fromtimestamp = dates[0].getTime();
		$('#return-date').datepick('option', 'minDate', dates[0] || null);
		}
	else
		{
		var totimestamp = dates[0].getTime();
		$('#pickup-date').datepick('option', 'maxDate', dates[0] || null);
		}
	setDays();
	}

function setDays()
	{
	var $bookingdatesform = $('#bookingdatesform');
	$('#lightbox').show();
	$bookingdatesform.css('z-index', '2');
	var pickupDate = $('#pickup-date').val();
	var returnDate = $('#return-date').val();
	if ( ! pickupDate || ! returnDate)
		return;
	$bookingdatesform.find('button').removeClass('disabled').prop('disabled', false);
	}

$(function()
	{
	$('#pickup-date,#return-date').datepick(
		{ 
    onSelect: customRange,
		showTrigger: '#calImg',
		dateFormat: 'D d M yyyy',
		minDate: new Date(2024, 6 - 1, 7),
		maxDate: new Date(2024, 10 - 1, 7)
		});
	$('.timesel').change(function()
		{
		setDays();
		});
	});

$(function()
	{
	$('#bookingdatesform').on('submit',function()
		{
		var $bookingdatesform = $(this);
		var pickupDate = $('#pickup-date').val();
		var returnDate = $('#return-date').val();
		if ( ! pickupDate || ! returnDate)
			return false;
		var pickupTime = 'AM';
		if ($('#pickup-time-pm').is(':checked'))
			pickupTime = 'PM';
		var returnTime = 'PM';
		//if ($('#return-time-am').is(':checked'))
		//	returnTime = 'AM';
		var snowplay_ind = $('#snowplay_ind').val();
		var fields = [];
		fields.push({name: 'pickup-date', value: pickupDate});
		fields.push({name: 'pickup-time', value: pickupTime});
		fields.push({name: 'return-date', value: returnDate});
		fields.push({name: 'return-time', value: returnTime});
		fields.push({name: 'snowplay_ind', value: snowplay_ind});
		//$('#lightboxwait').show();
		$.ajax(
			{
			type: 'POST',
			url: '/bizsite2/bookingDates.php',
			data: fields,
			dataType: 'json',
			success: function(data)
				{
				$('#numdays').text(data.days);
				$('#bkaccordion').html(data.persons).show();
				Foundation.reInit('accordion');
				if ( ! data.newperson)
					{
					$('#lightbox').hide();
					$('#nextbuttons').show();
					}
				$bookingdatesform.css('z-index', '0');
				$bookingdatesform.find('button').addClass('disabled').prop('disabled', true);
				if (data.total == 0)
					$('#totalcost').html('');
				else
					$('#totalcost').html('Booking Total: <strong>$'+data.total+'</strong> (Deposit <strong>$'+data.totaldeposit+'</strong>)');
				$('#insuranceyes').html(data.insurancelabel + ': <strong>$' + data.insurance + '</strong>');
				var $newperson = $('.newperson');
				if ($newperson.length)
					{
					$newperson.foundation();
					scrollToTarget($newperson);
					$newperson.find('input:text:first').focus();
					}
				}
			});
		return false;
		});
	var $bkaccordion = $('#bkaccordion');
	//$bkaccordion.on('change', '.skihire .radio, .skihire .checkbox', function()
	$bkaccordion.on('submit', '.skihire', function()
		{
		console.log('skihiresave');
		var $skihire = $(this).closest('.skihire');
		//$skihire.find('.radio, .checkbox').parent('label').removeClass('chosen');
		var $checked = $skihire.find(':checked');
		//$checked.parent('label').addClass('chosen');
		var $person = $(this).closest('.accordion-content').find('.person');
		var hire_person_id = $person.find('input[name="hire_person_id"]').val();
		var tyre_size = $skihire.find('input[name="'+hire_person_id+'_tyresize"]').val();
		var fields = [];
		fields.push({name: 'hire_person_id', value: hire_person_id});
		fields.push({name: 'tyre_size', value: tyre_size});
		$checked.each(function()
			{
			fields.push({name: 'product_id[]', value: $(this).val()});
			});
		if ( ! $checked.length)
			{
			$skihire.find('.buttonerror').html('Nothing selected');
			return false;
			}
		$.ajax(
			{
			type: 'POST',
			url: '/bizsite2/bookingSkihire.php',
			data: fields,
			dataType: 'json',
			success: function(data)
				{
				console.log(data);
				var $li = $skihire.closest('li')
				$li.find('.skihire').html(data.skihire);
				$skihire.find('.skihiresave').addClass('disabled').prop('disabled', true);
				$skihire.css('z-index', '0');
				$('#lightbox').hide();
				$li.find('.personcost').html('$'+data.personcost+' (Deposit $'+data.persondeposit+')');
				$('#totalcost').html('Booking Total: <strong>$'+data.total+'</strong> (Deposit <strong>$'+data.totaldeposit+'</strong>)');
				$('#insuranceyes').html(data.insurancelabel + ': <strong>$' + data.insurance + '</strong>');
				$('#nextbuttons').show();
				scrollToTargetAtBottom($('#nextbuttons'));
				}
			});
		return false;
		});
	$bkaccordion.on('click', '.skihireclear', function()
		{
		var $skihire = $(this).closest('.skihire');
		$skihire.find(':checked')
			.prop('checked', false)
			.parent('label').removeClass('chosen');
		$skihire.find('.skihiresave').addClass('disabled').prop('disabled', true);
		return false;
		});
	$('.skihire .stance:checked').each(function()
		{
		$(this).closest('.accordion-content').find('.person .stance').show();
		});

	if ($bkaccordion.find('li').length == 1)
		$bkaccordion.foundation('down', $bkaccordion.find('.accordion-content'));
		
	$bkaccordion.on('input change', '.skihire :input', function()
		{
		console.log('Skihire change');
		var $skihire = $(this).closest('.skihire');
		$skihire.find('.skihiresave').removeClass('disabled').prop('disabled', false);
		$skihire.css('z-index', '2');

		$skihire.find('.radio, .checkbox').parent('label').removeClass('chosen');
		var $checked = $skihire.find(':checked');
		$checked.parent('label').addClass('chosen');
		if ($checked.filter('.if1').length)
			$skihire.find('.stop1').prop({'checked': false, 'disabled': true});
		else
			$skihire.find('.stop1').prop({'disabled': false});
		if ($checked.filter('.if2').length)
			$skihire.find('.stop2').prop({'checked': false, 'disabled': true});
		else
			$skihire.find('.stop2').prop({'disabled': false});
		var $person = $(this).closest('.accordion-content').find('.person');
		if ($checked.filter('.stance').length)
			$person.find('.stance').show();
		else
			$person.find('.stance').hide();
		$skihire.find('.buttonerror').html('');
		$('#lightbox').show();
		});
	$bkaccordion.on('input change', '.person :input', function()
		{
		console.log('Person change');
		var $person = $(this).closest('.person');
		$person.find('button').removeClass('disabled').prop('disabled', false);
		$person.css('z-index', '2');
		$('#lightbox').show();
		});
	$bkaccordion.on('submit', '.person', function()
		{
		console.log('personsave');
		console.log($(this));
		var $person = $(this).closest('.person');
		console.log($person);
		var id = $person.find('input[name="id"]').val();
		var hire_person_id = $person.find('input[name="hire_person_id"]').val();
		var age = $person.find('#'+id+'_age').val();
		var fields = [];
		fields.push({name: 'hire_person_id', value: hire_person_id});
		fields.push({name: 'id', value: id});
		fields.push({name: 'first_name', value: $person.find('#'+id+'_first_name').val()});
		fields.push({name: 'last_name', value: $person.find('#'+id+'_last_name').val()});
		fields.push({name: 'age', value: age});
		fields.push({name: 'weight', value: $person.find('#'+id+'_weight').val()});
		fields.push({name: 'height', value: $person.find('#'+id+'_height').val()});
		fields.push({name: 'sex', value: $person.find("input[name='sex']:checked").val()});
		fields.push({name: 'ability', value: $person.find('#'+id+'_ability').val()});
		if (age == '< 10' || age == '10 - 14')
			fields.push({name: 'shoe_size', value: $person.find('#'+id+'_shoe_size_child').val()});
		else
			fields.push({name: 'shoe_size', value: $person.find('#'+id+'_shoe_size_adult').val()});
		fields.push({name: 'stance', value: $person.find('#'+id+'_stance').val()});
		$.ajax(
			{
			type: 'POST',
			url: '/bizsite2/bookingPerson.php',
			data: fields,
			dataType: 'json',
			success: function(data)
				{
				console.log(data);
				$person.removeClass('newperson');
				$person.find('input[name="hire_person_id"]').val(data.hire_person_id);
				$person.find('.personsave').addClass('disabled').prop('disabled', true);
				$person.closest('li').find('.personname').html(data.first_name+' '+data.last_name);
				var $li = $person.closest('li')
				var $skihire = $li.find('.skihire');
				var $skihireproducts = $li.find('.skihireproducts');
				$skihireproducts.html(data.skihire);
				console.log('person save personcost='+data.personcost);
				if (data.personcost)
					$li.find('.personcost').html('$'+data.personcost+' (Deposit $'+data.persondeposit+')');
				else
					$li.find('.personcost').html('');
				$('#totalcost').html('Booking Total: <strong>$'+data.total+'</strong> (Deposit <strong>$'+data.totaldeposit+'</strong>)');
				$('#insuranceyes').html(data.insurancelabel + ': <strong>$' + data.insurance + '</strong>');
				$person.css('z-index', '0');
				if (hire_person_id == 0)
					{
					$skihire.find('button').removeClass('disabled').prop('disabled', false);
					$skihire.css('z-index', '2');
					scrollToTarget($skihire);
					}
				else
					$('#lightbox').hide();
				}
			});
		return false;
		});
	$bkaccordion.on('change', '.select-age', function()
		{
		initShoeSize($(this).closest('.person'));
		});
	$('#addperson').on('click', function()
		{
		var id = $bkaccordion.find('li').length + 1;
		var fields = [];
		fields.push({name: 'id', value: id});
		$.ajax(
			{
			type: 'POST',
			url: '/bizsite2/bookingAddPerson.php',
			data: fields,
			dataType: 'json',
			success: function(data)
				{
				$bkaccordion.find('.accordion-item.is-active .accordion-content').each(function (i, section)
					{
					$bkaccordion.foundation('up', $(section));
					});
				var $person = $(data.person)
				$bkaccordion.append($person);
				//initShoeSize($person);
				//Foundation.reInit(['abide', 'accordion']);
				Foundation.reInit('accordion');
				$person.foundation();
				//$bkaccordion.closest('form').foundation();
				//$('#lightboxwait').hide();
				$('#lightbox').show();
				var $newperson = $('.newperson');
				if ($newperson.length)
					{
					setTimeout(function()
						{
						scrollToTarget($newperson);
						$newperson.find('input:text:first').focus();
						}, 1000);
					}
				}
			});
		return false;
		});
	$('#finishbooking').on('click', function()
		{
		$('#finishbooking').hide();
		$('#bookingfooter').show();
		var $bookingcontactform = $('#bookingcontactform');
		$bookingcontactform.foundation();
		scrollToTarget($bookingcontactform);
		return false;
		});
	$bkaccordion.on('click', '.personremove', function () 
		{
		var $li = $(this).closest('li');
		var hire_person_id = $li.find('input[name="hire_person_id"]').val();
		if (hire_person_id == 0)
			{
			$li.remove();
			$('#lightbox').hide();
			return false;
			}
		if ( ! confirm('Are you sure you want to remove this person?'))
			return false;
		$('#lightboxwait').show();
		$('#lightbox').hide();
		var fields = [];
		fields.push({name: 'hire_person_id', value: hire_person_id});
		$.ajax(
			{
			type: 'POST',
			url: '/bizsite2/bookingRemovePerson.php',
			data: fields,
			dataType: 'json',
			success: function(data)
				{
				$li.remove();
				$('#totalcost').html('Booking Total: <strong>$'+data.total+'</strong> (Deposit <strong>$'+data.totaldeposit+'</strong>)');
				$('#lightboxwait').hide();
				}
			});
		return false;
		});
	//initShoeSize($bkaccordion);

	$('#bookingfooter input[name="insurance_ind"]').on('change', function()
		{
		if (this.value == 1)
			{
			$('#insuranceyes').show();
			$('#insuranceno').hide();
			}
		else
			{
			$('#insuranceyes').hide();
			$('#insuranceno').show();
			}
		});

	var $newperson = $('.newperson');
	if ($newperson.length)
		{
		scrollToTarget($newperson);
		$newperson.find('input:text:first').focus();
		}
	});

function initShoeSize($root)
	{
	$root.find('.select-age').each(function()
		{
		var age = 'adult';
		if ($(this).prop("selectedIndex") == 1 || $(this).prop("selectedIndex") == 2)
			age = 'child';
		var $li = $(this).closest('li');
		if (age == 'adult')
			{
			$li.find('.adultshoeselect').show();
			$li.find('.childshoeselect').hide();
			}
		else
			{
			$li.find('.adultshoeselect').hide();
			$li.find('.childshoeselect').show();
			}
		});
	}
function scrollToTarget($target)
	{
	var adjustOffset = 60;
	var targetOffset = $target.offset().top - adjustOffset;
	console.log('scrollToTarget target.offset().top='+$target.offset().top+' targetOffset='+targetOffset);
	if (Math.abs(targetOffset - $(window).scrollTop()) > adjustOffset)
		$('html,body').animate({scrollTop: targetOffset}, 1000);
	}

function scrollToTargetAtBottom($target)
	{
	var targetOffset = $target.offset().top - $(window).height() + $target.height();
	if (targetOffset > $(document).scrollTop()) // only scroll down
		$('html,body').animate({scrollTop: targetOffset}, 1000);
	}
